.sidebar {
	display: flex;
	flex-direction: column;
	border: 2px solid rgba(153, 255, 51, .5);
	padding: 20px;
	border-radius: 5px;
}

.sidebar .field {
	display: flex;
	align-items: center;
	justify-content: center;
}

.field:first-child {
	margin-bottom: 15px;
}

.priorHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.priorHeader h3{
	font-size: 22px;
	text-shadow: -2px 2px #ff3399;
}

.reload {
	cursor: pointer;
	font-size: 30px;
	text-shadow: 2px -2px #ff3399;
}