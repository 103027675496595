.content-wrapper {
  border-width: 10px 5px;
  border-radius: 10px;
  margin: 5px 10px;
}

.container {
  max-width: 800px;
  margin: 100px auto 0 auto;
}

a {
  text-decoration: none;
} 

.title {
  text-align: center;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-size: 24px;
  color: #ff3399;
  text-shadow: 2px -2px #99FF33;;
}
  
.todos {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  margin-top: 10px;
}
.todo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.done {
  text-decoration: line-through;
}

.delete {
  width: 50px;
  height: 35px;
  cursor: pointer;
  box-shadow: inset 0 0 2px lightgrey;
  border-radius: 3px;
}

.btn-container{
  display: flex;
  justify-content: flex-end;
}

.btn {
  outline: none;
  background-color: #fff;
  border: 2px solid greenyellow;
  font-size: 18px;
  border-radius: 5px;
  width: 130px;
  min-height: 50px;
  margin-top: 5px;
  cursor: pointer;
  color: #ff3399;
  text-shadow: 0px 0px 1px black;
}

.field {
  position: relative;
  list-style: none;
  width: 100%;
  height: 50px;
  box-shadow: 2px 2px 10px 0 #99FF33; /*#FF3399*/
  border-radius: 5px;
  padding: 13px;
  font-size: 20px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #f0f8fff0;
  text-shadow: 0px 0px 1px black;
  overflow: hidden;
}

.todoInput {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.container .info {
  display: flex;
  justify-content: space-between;
  border: 2px solid greenyellow;
  box-shadow: unset;
  height: unset;
}

.help {
  display: flex;
  justify-content: flex-end;
}
.help-inner {
  font-size: 24px;
  width: 50px;
  border: 1px solid #99FF33;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}

footer .container {
  display: flex;
  justify-content: space-around;
}

footer a {
  display: block;
}

.hidden {
  display: none;
}

.help-inner, .field {
  user-select: none; /* Стандартное свойство */
  -webkit-user-select: none; /* Для Chrome и Safari */
  -moz-user-select: none; /* Для Firefox */
  -ms-user-select: none; /* Для IE и Edge */
}

@media (min-width: 320px) and (max-width: 522px) { 
  .field {
    height: 70px;
  }
 }

 @media (max-width: 320px){ 
  .field {
    height: 100px;
  }
 }