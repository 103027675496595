body {
  margin: 0;
  padding: 0;
  background-color: #99FF33;
  background-image: url('./assets/background.jpg');
}

* {
  box-sizing: border-box;
  color: #99FF33;
}